import { datatableInit } from "../functions/datatableInit";
import Swal from 'sweetalert2'
import axios from "axios";
import {select2Init} from "../functions/select2Init";

const loader = window.customLoader

const locationList = () => {

    $('#search-filters').on('click', () => {
        $('.datatable').DataTable().ajax.reload()
    })

    $('#reset-filters').on('click', () =>  {
        $('#customer').val('').change();
        $('#macroregion').val('').change();
        $('#region').val('').change();
        $('#district').val('').change();
        $('#area').val('').change();
        $('.datatable').DataTable().ajax.reload()
    })

    $(document).on('click', '.remove-location', function (e){
        e.preventDefault()
        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: 'Czy na pewno chcesz usunąć lokalizację?',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: 'Nie, anuluj',
            showConfirmButton: true,
            confirmButtonText: 'Tak, usuń',
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                $.ajax({
                    "beforeSend": function (){
                        loader.show()
                    },
                    type: 'post',
                    url: Routing.generate('location_remove', {id: id}),
                    "complete": function (){
                        loader.hide()
                    }
                }).done(d => {
                    if (!d.error)
                    {
                        Swal.fire({
                            icon: 'success',
                            title: 'Lokalizacja została usunięta',
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        }).then( () => {
                            $('.datatable').DataTable().ajax.reload()
                        })
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: 'Nie udało się usunąć lokalizacji',
                            text: d.error,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        })
                    }

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: 'Anulowano usuwanie lokalizacji',
                    confirmButtonText: 'Zamknij',
                    confirmButtonColor: '#3f51b5',
                })

            }
        })
    })

    $(document).on('click', '.export-location', function () {

        const url = (Routing.generate('location_export'))
        window.open(url, '_blank')
    })

    $(document).on('click', '.import-location', async function (e) {
        e.preventDefault()


        Swal.fire({
            template: '#my-template',
            preConfirm: () => {
                const client = document.getElementById('import_client').value
                const fileImport = document.getElementById('import_fileImport').files[0]
                if (!fileImport || !client)
                {
                    Swal.showValidationMessage(`Uzupełnij wszystkie pola`)
                }
                return {
                    client: client, fileImport: fileImport
                }
            },

        }).then((result) => {

            const file = result.value['fileImport']
            const client = result.value['client']

            const data = new FormData()
            data.append( 'file', file )
            data.append( 'customer', client )

            loader.show()

            axios.post(Routing.generate('location_import', {confirmed: 0}), data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }).then(function (response){

                loader.hide()

                if(response['data']['ok'])
                {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Czy na pewno chcesz zaimportować lokalizacje?',
                        text: 'Próbujesz zaimportować ' + response['data']['rows'] + ' lokalizacji',
                        showCancelButton: true,
                        confirmButtonText: 'Tak, importuj',
                        cancelButtonText: 'Nie, anuluj',
                        confirmButtonColor: '#3f51b5',
                        cancelButtonColor: '#6c757d',
                    }).then((result) => {
                        if (result.isConfirmed)
                        {
                            loader.show()
                            axios.post(Routing.generate('location_import', {confirmed: 1}), data, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                },
                            }).then(function (response){
                                loader.hide()
                                if(response['data']['ok'])
                                {
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Import zakończony sukcesem',
                                        confirmButtonText: 'Zamknij',
                                        confirmButtonColor: '#3f51b5',
                                    }).then(() => {
                                        $('.datatable').DataTable().ajax.reload()
                                    })
                                }
                                else
                                {
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Import nie udany',
                                        text: response["data"]["error"],
                                        confirmButtonText: 'Zamknij',
                                        confirmButtonColor: '#3f51b5',
                                    })
                                }
                            })
                        }
                    })
                }
                else
                {
                    Swal.fire({
                        icon: 'error',
                        title: 'Import nie udany',
                        text: response["data"]["error"],
                        confirmButtonText: 'Zamknij',
                        confirmButtonColor: '#3f51b5',
                    })
                }
            })
        })
    })

    $('.datatable tbody').on('click', '.open-shop', function () {

        loader.show()

        const id = $(this).data('id')
        const tr = $(this).closest('tr')
        const row = $('.datatable').DataTable().row( tr );

        if ( row.child.isShown() ) {
            row.child.hide();
            tr.removeClass('shown');

            loader.hide()
        }
        else {
            $.ajax({
                type: 'post',
                url: Routing.generate('location_details', {id: id}),
            }).then(result => {

                if (result['number'] == null)
                {
                    result['number'] = 'brak'
                }
                if (result['address'] == null)
                {
                    result['address'] = 'brak'
                }
                if (result['city'] == null)
                {
                    result['city'] = 'brak'
                }
                if (result['zipCode'] == null)
                {
                    result['zipCode'] = 'brak'
                }
                if (result['phoneNumber'] == null)
                {
                    result['phoneNumber'] = 'brak'
                }
                if (result['macroregion'] == null)
                {
                    result['macroregion'] = 'brak'
                }
                if (result['region'] == null)
                {
                    result['region'] = 'brak'
                }
                if (result['district'] == null)
                {
                    result['district'] = 'brak'
                }
                if (result['area'] == null)
                {
                    result['area'] = 'brak'
                }

                row.child(

                    '<table class="location-info" style="padding-left:50px;">' +
                    '<tr>' +
                    '<td class="text-center">Numer lokalizacji</td>' +
                    '<td class="text-center">Adres</td>' +
                    '<td class="text-center">Kod pocztowy</td>' +
                    '<td class="text-center">Numer telefonu</td>' +
                    '<td class="text-center">Makroregion</td>' +
                    '<td class="text-center">Region</td>' +
                    '<td class="text-center">Okręg</td>' +
                    '<td class="text-center">Rejon</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td class="text-center">' + result['number'] + '</td>' +
                    '<td class="text-center">' + result['address'] + ' ' + result['city'] +'</td>' +
                    '<td class="text-center">' + result['zipCode'] + '</td>' +
                    '<td class="text-center">' + result['phoneNumber'] + '</td>' +
                    '<td class="text-center">' + result['macroregion'] + '</td>' +
                    '<td class="text-center">' + result['region'] + '</td>' +
                    '<td class="text-center">' + result['district'] + '</td>' +
                    '<td class="text-center">' + result['area'] + '</td>' +
                    '</tr>' +
                    '</table>'

                ).show();
                tr.addClass('shown');

                loader.hide()
            })
        }
    } );

    $(() => {
        datatableInit('.datatable', {

            'columns': [
                {'name': 'l.number'},
                {'name': 'c.companyName'},
                {'name': 'l.address'},
                {'name': 'l.city'},
                {'name': 'l.zipCode'},
                {'name': 'a.surname'},
                {'name': 'l.remarks'},
                {'name': 'unavailable', 'orderable': false},
                {'name': 'l.comment'},
                {'name': 'l.DateAdd'},
                {'name': 'actions', 'orderable': false},
            ],
            order: [0, 'asc'],
            bServerSide: true,
            stateSave: true,
            paginate: true,
            processing: true,
            bDestroy: true,
            autoWidth: false,
            lengthMenu: [25,50,100],
            ajax: {
                beforeSend: function (){
                    loader.show()
                },
                data: (d) => {
                    d.customer = $('#customer :selected').data('customer')
                    d.macroregion = $('#macroregion :selected').data('macroregion');
                    d.region = $('#region :selected').data('region');
                    d.district = $('#district :selected').data('district');
                    d.area = $('#area :selected').data('area');
                    // d.newLocation = $('#newLocation').is(':checked');
                },
                url: Routing.generate('location_list_ajax'),
                type: "POST",
                complete: function (){
                    loader.hide()
                }
            },
        })

        select2Init('select[data-select=select2]')
    })
}

window.initLocationList = () => {
    locationList()
}